export default {
  lastUpdated: (state) => state.lastUpdated,
  dateRange: (state) => state.dateRange,
  dialogList: (state) => state.dialogList,
  campaignList: (state) => state.campaignList,
  deviceData: (state) => state.deviceData,
  browserData: (state) => state.browserData,
  agentsData: (state) => state.agentsData,
  routeClicks: (state) => state.routeClicks,
  topReferers: (state) => state.topReferers,
  topOrigins: (state) => state.topOrigins,
  totalVisitors: (state) => state.totalVisitors,
  uniqueVisitors: (state) => state.uniqueVisitors,
  returningVisitors: (state) => state.returningVisitors,
  topVisitors: (state) => state.topVisitors,
  contactFormShownCount: (state) => state.contactFormShownCount,
  visitorsRating: (state) => state.visitorsRating,
  contactFormFilledCount: (state) => state.contactFormFilledCount,
  contactFormBookedAppointmentCount: (state) => state.contactFormBookedAppointmentCount,
  showsCount: (state) => state.generalInfo.showsCount,
  requestCount: (state) => state.generalInfo.requestCount,
  chatRequestCount: (state) => state.generalInfo.chatRequestCount,
  audioRequestCount: (state) => state.generalInfo.audioRequestCount,
  videoRequestCount: (state) => state.generalInfo.videoRequestCount,
  sessionsCount: (state) => state.generalInfo.sessionsCount,
  chatSessionCount: (state) => state.generalInfo.chatSessionCount,
  audioSessionCount: (state) => state.generalInfo.audioSessionCount,
  videoSessionCount: (state) => state.generalInfo.videoSessionCount,
  sessionDuration: (state) => state.generalInfo.sessionDuration,
  showsData: (state) => state.showsData,
  requestsData: (state) => state.requestsData,
  sessionsData: (state) => state.sessionsData,

  campaignShowsCount: (state) => state.campaignInfo.showsCount,
  campaignRequestCount: (state) => state.campaignInfo.requestCount,
  campaignChatRequestCount: (state) => state.campaignInfo.chatRequestCount,
  campaignAudioRequestCount: (state) => state.campaignInfo.audioRequestCount,
  campaignVideoRequestCount: (state) => state.campaignInfo.videoRequestCount,
  campaignSessionsCount: (state) => state.campaignInfo.sessionsCount,
  campaignChatSessionCount: (state) => state.campaignInfo.chatSessionCount,
  campaignAudioSessionCount: (state) => state.campaignInfo.audioSessionCount,
  campaignVideoSessionCount: (state) => state.campaignInfo.videoSessionCount,
  campaignSessionDuration: (state) => state.campaignInfo.sessionDuration,
  campaignShowsData: (state) => state.campaignShowsData,
  campaignRequestsData: (state) => state.campaignRequestsData,
  campaignSessionsData: (state) => state.campaignSessionsData,

  callCenterReport: (state) => state.callCenterReport,
  endScreenFeedbackData: (state) => state.endScreenFeedbackData,

  vegaAgentConversationRating: (state) => state.vegaAgentConversationRating,
  vegaConversationStatus: (state) => state.vegaConversationStatus,
  vegaVisitorAnalytics: (state) => state.vegaVisitorAnalytics,
  vegaVisitorsCountPerDay: (state) => state.vegaVisitorsCountPerDay,
  vegaFilledContactFormStatus: (state) => state.vegaFilledContactFormStatus,
  vegaScoresAnalytics: (state) => state.vegaScoresAnalytics,
  vegaVisitorScoreFlow: (state) => state.vegaVisitorScoreFlow,
  selectedVegaUserFlow: (state) => state.selectedVegaUserFlow,
  vegaCampaigns: (state) => state.vegaCampaigns,
  vegaDataFetched: (state) => state.vegaDataFetched,
  vegaReports: (state) => state.vegaReports,

  isCollapsedWebsiteAnalyticsBlock: (state) => state.isCollapsedWebsiteAnalyticsBlock,
  isCollapsedCampaignsBlock: (state) => state.isCollapsedCampaignsBlock,
  isCollapsedCommunicationAnalyticsBlock: (state) => state.isCollapsedCommunicationAnalyticsBlock,
  isFetchedWebsiteAnalyticsBlockData: (state) => state.isFetchedWebsiteAnalyticsBlockData,
  isFetchedCampaignsBlockData: (state) => state.isFetchedCampaignsBlockData,
  isFetchedCommunicationAnalyticsBlockData: (state) => state.isFetchedCommunicationAnalyticsBlockData
}
