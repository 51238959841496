export default {
  UPDATE_LAST_UPDATE(state, value) {
    state.lastUpdated = value
  },
  UPDATE_DATE_RANGE(state, value) {
    state.dateRange = value
  },
  UPDATE_DIALOG_LIST(state, value) {
    state.dialogList = value
  },
  UPDATE_CAMPAIGN_LIST(state, value) {
    state.campaignList = value
  },
  UPDATE_DEVICE_DATA(state, value) {
    state.deviceData = value
  },
  UPDATE_BROWSER_DATA(state, value) {
    state.browserData = value
  },
  UPDATE_AGENTS_DATA(state, value) {
    state.agentsData = value
  },
  UPDATE_ROUTE_CLICKS(state, value) {
    state.routeClicks = value
  },
  UPDATE_TOP_REFERERS(state, value) {
    state.topReferers = value
  },
  UPDATE_TOP_ORIGINS(state, value) {
    state.topOrigins = value
  },
  UPDATE_CALL_CENTER_USER_WORKTIME(state, value) {
    state.callCenterReport.workingTimes = value
  },
  UPDATE_CALL_CENTER_CALL_REQUESTS(state, value) {
    state.callCenterReport.callRequests = value
  },
  UPDATE_TOTAL_VISITORS(state, value) {
    state.totalVisitors = value
  },
  UPDATE_UNIQUE_VISITORS(state, value) {
    state.uniqueVisitors = value
  },
  UPDATE_RETURNING_VISITORS(state, value) {
    state.returningVisitors = value
  },
  UPDATE_TOP_VISITORS(state, value) {
    state.topVisitors = value
  },
  UPDATE_CONTACT_FORM_SHOWN_COUNT(state, value) {
    state.contactFormShownCount = value
  },
  UPDATE_CONTACT_FORM_FILLED_COUNT(state, value) {
    state.contactFormFilledCount = value
  },
  UPDATE_CONTACT_FORM_BOOKED_APPOINTMENT_COUNT(state, value) {
    state.contactFormBookedAppointmentCount = value
  },
  UPDATE_SHOWS_COUNT(state, value) {
    state.generalInfo.showsCount = value
  },
  UPDATE_REQUESTS_COUNT(state, value) {
    state.generalInfo.requestCount = value
  },
  UPDATE_CHAT_REQUEST_COUNT(state, value) {
    state.generalInfo.chatRequestCount = value
  },
  UPDATE_AUDIO_REQUEST_COUNT(state, value) {
    state.generalInfo.audioRequestCount = value
  },
  UPDATE_VIDEO_REQUEST_COUNT(state, value) {
    state.generalInfo.videoRequestCount = value
  },
  UPDATE_CHAT_SESSION_COUNT(state, value) {
    state.generalInfo.chatSessionCount = value
  },
  UPDATE_AUDIO_SESSION_COUNT(state, value) {
    state.generalInfo.audioSessionCount = value
  },
  UPDATE_VIDEO_SESSION_COUNT(state, value) {
    state.generalInfo.videoSessionCount = value
  },
  UPDATE_SESSIONS_COUNT(state, value) {
    state.generalInfo.sessionsCount = value
  },
  UPDATE_SESSIONS_DURATION(state, value) {
    state.generalInfo.sessionDuration = value
  },
  UPDATE_SHOWS_DATA(state, value) {
    state.showsData = value
  },
  UPDATE_REQUESTS_DATA(state, value) {
    state.requestsData = value
  },
  UPDATE_SESSIONS_DATA(state, value) {
    state.sessionsData = value
  },
  /* CAMPAIGNS */
  UPDATE_CAMPAIGN_SHOWS_COUNT(state, value) {
    state.campaignInfo.showsCount = value
  },
  UPDATE_CAMPAIGN_REQUESTS_COUNT(state, value) {
    state.campaignInfo.requestCount = value
  },
  UPDATE_CAMPAIGN_CHAT_REQUEST_COUNT(state, value) {
    state.campaignInfo.chatRequestCount = value
  },
  UPDATE_CAMPAIGN_AUDIO_REQUEST_COUNT(state, value) {
    state.campaignInfo.audioRequestCount = value
  },
  UPDATE_CAMPAIGN_VIDEO_REQUEST_COUNT(state, value) {
    state.campaignInfo.videoRequestCount = value
  },
  UPDATE_CAMPAIGN_CHAT_SESSION_COUNT(state, value) {
    state.campaignInfo.chatSessionCount = value
  },
  UPDATE_CAMPAIGN_AUDIO_SESSION_COUNT(state, value) {
    state.campaignInfo.audioSessionCount = value
  },
  UPDATE_CAMPAIGN_VIDEO_SESSION_COUNT(state, value) {
    state.campaignInfo.videoSessionCount = value
  },
  UPDATE_CAMPAIGN_SESSIONS_COUNT(state, value) {
    state.campaignInfo.sessionsCount = value
  },
  UPDATE_CAMPAIGN_SESSIONS_DURATION(state, value) {
    state.campaignInfo.sessionDuration = value
  },
  UPDATE_CAMPAIGN_SHOWS_DATA(state, value) {
    state.campaignShowsData = value
  },
  UPDATE_CAMPAIGN_REQUESTS_DATA(state, value) {
    state.campaignRequestsData = value
  },
  UPDATE_CAMPAIGN_SESSIONS_DATA(state, value) {
    state.campaignSessionsData = value
  },
  UPDATE_ENDSCREEN_FEEDBACK_DATA(state, value) {
    state.endScreenFeedbackData = value
  },
  UPDATE_VISITORS_RATING_DATA(state, value) {
    state.visitorsRating = {
      totalCount: value.totalCount,
      goodCount: value.goodCount,
      supportCount: value.supportCount,
      neutralCount: value.neutralCount,
      wasteOfTimeCount: value.wasteOfTimeCount
    }
  },
  UPDATE_VEGA_AGENT_CONVERSATION_RATING_DATA(state, value) {
    state.vegaAgentConversationRating = value
  },
  UPDATE_VEGA_CONVERSATION_STATUS_DATA(state, value) {
    state.vegaConversationStatus = value
  },
  UPDATE_VEGA_VISITOR_ANALYTICS_DATA(state, value) {
    state.vegaVisitorAnalytics = value
  },
  UPDATE_VEGA_VISITORS_COUNT_PER_DAY_DATA(state, value) {
    state.vegaVisitorsCountPerDay = value
  },
  UPDATE_VEGA_FILLED_CONTACT_FORM_STATUS_DATA(state, value) {
    state.vegaFilledContactFormStatus = value
  },
  UPDATE_VEGA_SCORES_ANALYTICS_DATA(state, value) {
    state.vegaScoresAnalytics = value
  },
  UPDATE_VEGA_CAMPAIGNS_DATA(state, value) {
    state.vegaCampaigns = value
  },
  UPDATE_VEGA_VISITOR_SCORE_FLOW_DATA(state, value) {
    state.vegaVisitorScoreFlow = value
  },
  UPDATE_SELECTED_VEGA_USER_FLOW(state, value) {
    state.selectedVegaUserFlow = value
  },
  UPDATE_VISITORS_RATING_BUYING_INTENT(state, value) {
    state.vegaReports.visitorsRatingsBuyingIntent = value
  },
  UPDATE_VEGA_DATA_FETCHED(state, payload) {
    state.vegaDataFetched = payload
  },
  UPDATE_IS_COLLAPSED_WEBSITE_ANALYTICS_BLOCK(state, payload) {
    state.isCollapsedWebsiteAnalyticsBlock = payload
  },
  UPDATE_IS_COLLAPSED_CAMPAIGNS_BLOCK(state, payload) {
    state.isCollapsedCampaignsBlock = payload
  },
  UPDATE_IS_COLLAPSED_COMMUNICATION_ANALYTICS_BLOCK(state, payload) {
    state.isCollapsedCommunicationAnalyticsBlock = payload
  },
  UPDATE_IS_FETCHED_WEBSITE_ANALYTICS_BLOCK_DATA(state, payload) {
    state.isFetchedWebsiteAnalyticsBlockData = payload
  },
  UPDATE_IS_FETCHED_CAMPAIGNS_BLOCK_DATA(state, payload) {
    state.isFetchedCampaignsBlockData = payload
  },
  UPDATE_IS_FETCHED_COMMUNICATION_ANALYTICS_BLOCK_DATA(state, payload) {
    state.isFetchedCommunicationAnalyticsBlockData = payload
  },
  RESET_DASHBOARD_STORE(state) {
    state.lastUpdated = null
    state.dateRange = {
      endDate: null,
      startDate: null
    }
    state.dialogList = []
    state.campaignList = []
    state.deviceData = []
    state.browserData = []
    state.agentsData = []
    state.topReferers = []
    state.topOrigins = []
    state.totalVisitors = 0
    state.callCenterReport = {
      workingTimes: [],
      callRequests: []
    }
    state.vegaReports = {
      visitorsRatingsBuyingIntent: []
    }
    state.uniqueVisitors = 0
    state.returningVisitors = 0
    state.topVisitors = []
    state.contactFormShownCount = 0
    state.contactFormFilledCount = 0
    state.contactFormBookedAppointmentCount = 0
    state.visitorsRating = {
      totalCount: 0,
      goodCount: 0,
      supportCount: 0,
      neutralCount: 0,
      wasteOfTimeCount: 0
    }
    state.generalInfo = {
      showsCount: 0,
      requestCount: 0,
      chatRequestCount: 0,
      audioRequestCount: 0,
      videoRequestCount: 0,
      sessionsCount: 0,
      chatSessionCount: 0,
      audioSessionCount: 0,
      videoSessionCount: 0,
      sessionDuration: '00:00:00'
    }
    state.campaignInfo = {
      showsCount: 0,
      requestCount: 0,
      chatRequestCount: 0,
      audioRequestCount: 0,
      videoRequestCount: 0,
      sessionsCount: 0,
      chatSessionCount: 0,
      audioSessionCount: 0,
      videoSessionCount: 0,
      sessionDuration: '00:00:00'
    }
    state.showsData = []
    state.requestsData = []
    state.sessionsData = []
    state.campaignShowsData = []
    state.campaignRequestsData = []
    state.campaignSessionsData = []
    state.endScreenFeedbackData = []
  }
}
